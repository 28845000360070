import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import '../static/styles/PhotoGallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import LazyLoad from 'react-lazyload'; // Import LazyLoad

import photo1 from '../static/media/Gallery/16/Debki_na_lato_1.jpg';
import photo2 from '../static/media/Gallery/16/Debki_na_lato_2.jpg';
import photo3 from '../static/media/Gallery/16/Debki_na_lato_3.jpg';
import photo4 from '../static/media/Gallery/16/Debki_na_lato_4.jpg';
import photo5 from '../static/media/Gallery/16/Debki_na_lato_5.jpg';
import photo6 from '../static/media/Gallery/16/Debki_na_lato_6.jpg';
import photo7 from '../static/media/Gallery/16/Debki_na_lato_7.jpg';
import photo8 from '../static/media/Gallery/16/Debki_na_lato_8.jpg';
import photo9 from '../static/media/Gallery/16/Debki_na_lato_9.jpg';
import photo10 from '../static/media/Gallery/16/Debki_na_lato_10.jpg';        
import photo11 from '../static/media/Gallery/16/Debki_na_lato_11.jpg';        
import photo12 from '../static/media/Gallery/16/Debki_na_lato_12.jpg';
import photo13 from '../static/media/Gallery/16/Debki_na_lato_13.jpg';        
import photo14 from '../static/media/Gallery/16/Debki_na_lato_14.jpg';        
import photo15 from '../static/media/Gallery/16/Debki_na_lato_15.jpg';        
import photo16 from '../static/media/Gallery/16/Debki_na_lato_16.jpg';        
import photo17 from '../static/media/Gallery/16/Debki_na_lato_17.jpg';        
import photo18 from '../static/media/Gallery/16/Debki_na_lato_18.jpg';        
import photo19 from '../static/media/Gallery/16/Debki_na_lato_19.jpg';        
import photo20 from '../static/media/Gallery/16/Debki_na_lato_20.jpg';        
import photo21 from '../static/media/Gallery/16/Debki_na_lato_21.jpg';        
import photo22 from '../static/media/Gallery/16/Debki_na_lato_22.jpg';
import photo23 from '../static/media/Gallery/16/Debki_na_lato_23.jpg';        
import photo24 from '../static/media/Gallery/16/Debki_na_lato_24.jpg';        
import photo25 from '../static/media/Gallery/16/Debki_na_lato_25.jpg';        
import photo27 from '../static/media/Gallery/16/Debki_na_lato_27.jpg';
import photo28 from '../static/media/Gallery/16/Debki_na_lato_28.jpg';
import photo29 from '../static/media/Gallery/16/Debki_na_lato_29.jpg';
import photo30 from '../static/media/Gallery/16/Debki_na_lato_30.jpg';
import photo31 from '../static/media/Gallery/16/Debki_na_lato_31.jpg';
import photo32 from '../static/media/Gallery/16/Debki_na_lato_32.jpg';


library.add(faChevronLeft, faChevronRight);

Modal.setAppElement('#root');


const photos = [
  { src: photo1, description: "Domki letniskowe Dębki Na Lato" },
  { src: photo2, description: "Dębki Na Lato – plac zabaw" },
  { src: photo3, description: "Dębki Na Lato – nasz statek piracki" },
  { src: photo4, description: "Dębki Na Lato – zadaszone tarasy domków" },
  { src: photo5, description: "Dębki Na Lato – piaskownica na placu zabaw" },
  { src: photo6, description: "Dębki Na Lato – trawnik na placu zabaw" },
  { src: photo7, description: "Dębki Na Lato – o zmierzchu" },
  { src: photo8, description: "Dębki Na Lato – salonik na parterze domku" },
  { src: photo9, description: "Dębki Na Lato – kominek na parterze domku" },
  { src: photo10, description: "Dębki Na Lato – kącik jadalny" }, 
  { src: photo11, description: "Dębki Na Lato – aneks kuchenny na parterze domku" },
  { src: photo12, description: "Dębki Na Lato – możliwość samodzielnego kucharzenia" },
  { src: photo13, description: "Dębki Na Lato – salonik na parterze domku" },
  { src: photo14, description: "Dębki Na Lato – sypialnia na piętrze domku" },
  { src: photo15, description: "Dębki Na Lato – sypialnia na piętrze domku" },
  { src: photo16, description: "Dębki Na Lato – sypialnia na piętrze domku" },
  { src: photo17, description: "Dębki Na Lato – łazienka na parterze domku" },
  { src: photo18, description: "Dębki Na Lato – mały apartament 2-osobowy" },
  { src: photo19, description: "Dębki Na Lato – mały apartament 2-osobowy" },
  { src: photo20, description: "Dębki Na Lato – mały apartament 2-osobowy" },
  { src: photo21, description: "Dębki Na Lato – łazienka w małym apartamencie" },
  { src: photo22, description: "Dębki Na Lato – mały apartament 2-osobowy" },
  { src: photo23, description: "Dębki Na Lato – przystań rybacka przy wejściu nr 19" },
  { src: photo24, description: "Dębki Na Lato – spływy kajakowe malowniczą rzeką Piaśnicą" },
  { src: photo25, description: "Dębki Na Lato – wejście na plażę nr 30, przystanek rowerowy R13" },
  { src: photo27, description: "Dębki Na Lato – niezwykłe zachody słońca nad Bałtykiem" },
  { src: photo28, description: "Dębki Na Lato – nasz ośrodek o zmierzchu" },
  { src: photo29, description: "Dębki Na Lato – huśtawki, zjeżdżalnia i ścianka wspinaczkowa dla maluchów" },
  { src: photo30, description: "Dębki Na Lato – zachęcamy do aktywności dzieci i dorosłych" },
  { src: photo31, description: "Dębki Na Lato – plac zabaw widoczny z piętra domku" },
  { src: photo32, description: "Dębki Na Lato – nasz piracki statek gotowy do letniego rejsu" }
];

const PhotoGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    if (window.innerWidth > 768) {
      setCurrentIndex(index);
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentIndex(0);
  };

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const prevPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  return (
    <div className="photo-gallery">
      <div className="gallery-header">
        <h2 id='n7x2'>Galeria</h2>
      </div>
      <div className="thumbnails-container">
        {photos.map((photo, index) => (
          <motion.div
            key={index}
            className="thumbnail-wrapper"
            whileHover={{ scale: 1.1 }}
            onClick={() => openModal(index)}
          >
            <LazyLoad height={200} offset={100} once> {/* LazyLoad component */}
              <img
                src={photo.src}
                alt={`photo-${index}`}
                className="photo-thumbnail"
              />
            </LazyLoad>
            <div>
              <p className='du'>{photo.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="modal-content"
          >
            <button className="modal-nav-btn prev" onClick={prevPhoto}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <img src={photos[currentIndex].src} alt="Selected" className="modal-image" />
            <div className="modal-description">
              <span>{photos[currentIndex].description}</span>
            </div>
            <button className="modal-nav-btn next" onClick={nextPhoto}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </motion.div>
        )}
      </Modal>
    </div>
  );
};

export default PhotoGallery;
