import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Add all icons to the library so you can use them dynamically
library.add(fas, far, fab);

const icons = [
  { icon: ['fas', 'house'], title: '9 domków', description: '6-7 osobowych' },
  { icon: ['fas', 'umbrella-beach'], title: '850 m', description: 'od plaży' },
  { icon: ['fas', 'bed'], title: '2 sypialnie', description: 'w każdym domku' },
  { icon: ['fas', 'child'], title: 'Plac zabaw', description: 'dla dzieci' },
];

const IconsSection = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
      padding: '10px',
    },
    box: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap', // Allows items to wrap on smaller screens
      width: '100%', // Changed from 80% to 100% to fit smaller screens
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#F7F9FA', // Optional background color
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '10px 20px', // Increased margin for better spacing on mobile
      minWidth: '120px', // Minimum width to prevent icons from being too small on mobile
    },
    icon: {
      marginBottom: '10px',
      color: 'black', // Optional icon color
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.2em',
      marginBottom: '5px',
      textAlign: 'center',
    },
    description: {
      textAlign: 'center',
      fontSize: '0.9em', // Smaller font size for mobile
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        {icons.map((item, index) => (
          <div key={index} style={styles.iconContainer}>
            <FontAwesomeIcon icon={item.icon} size="3x" style={styles.icon} />
            <div style={styles.title}>{item.title}</div>
            <div style={styles.description}>{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconsSection;
